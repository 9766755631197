// src/controllers/content_loader_controller.js
import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'


export default class extends Controller {

  connect() {
    StimulusReflex.register(this)
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      var element = document.getElementById("message");
      if(!element) {
        this.stimulate('NotifyReflex#done')
      }
    }, 60000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
